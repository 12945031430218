.errorMessage {
  color: red;
}
.formDiv {
  margin-top: -15%;
  width: calc(100% - 100px);
  margin-left: 100px;
  padding: 5px 40px;
}

.buttonConfirm {
  background: #9A6DF933 0% 0% no-repeat padding-box;
  border: 2px solid #9A6DF9;
  border-radius: 31px;
  opacity: 1;
  font-family: 'TT-Norms';
  font-size: 16px;
  letter-spacing: 0px;
  color: #151a35;
  opacity: 1;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 5px;
  display: block;
  outline: none;
  margin-bottom: 10px;
}

.formDiv .form-label {
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .formDiv {
    width: 100%;
    margin-left: 0;
  }
}

.open-lateral-menu {
  width: 77.5%;
  margin-left: 22.5%;
}

.error-span {
  color: red;
  font-size: 14px;
  font-weight: bold;
}
