header {
  position: absolute;
  top: 0;
  margin-bottom: 10%;
}

header img {
  width: 200px;
}

.loginHeader {
  margin: 20px 0 5px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginHeader div {
  margin-left: auto;
}

.loginHeader img {
  width: 100px;
}
