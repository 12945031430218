.logoImage {
  display: block;
  margin: 15% auto 0 auto;
}

.openLogo {
  width: 90%;
}

.closedLogo {
  width: 75%;
}

.iconLateral {
  margin-right: 5%;
  width: 32px;
  height: 32px;
}
.navlinkSelected {
  color: white;
  font-size: medium;
}

.navlink a,
.navlinkSelected a {
  color: unset;
  text-decoration: unset;
}

.navlink {
  color: #8a8d9a;
  font-size: medium;
  width: 260px;
}
.navlink:hover {
  color: #fff;
}
.navlinkSelected:hover {
  color: #fff;
}
.navlink:focus {
  color: #fff;
}
.navlinkSelected:focus {
  color: #fff;
}
.navLinks {
  margin-top: 20%;
}
.navDiv {
  background-color: #151a35;
  position: fixed;
  z-index: 100;
  left: 0;
  height: 100%;
  flex: 1;
  width: 22.5%;
  transition: width 0.25s;
}

.navDiv .nav-item,
.navDiv .navlink {
  width: 100%;
}

.toggler {
  position: absolute;
  left: calc(100% - 35px);
  top: 95%;
  border-radius: 8px;
  width: 30px;
  border: none;
  background-color: #151a35;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: left 0.5s;
}

.moved {
  left: 0;
  width: 100px;
  padding-top: 150px;
}

.open-toggler {
  left: calc(100% - 35px);
}

@keyframes fade-in {
  from {
    left: -22.5%;
  }

  to {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .navDiv {
    width: 80%;
  }

  .moved {
    left: -80%;
  }

  .open-toggler {
    left: 102.5%;
  }
}

@media (max-width: 914px) and (orientation: landscape) {
  .navDiv {
    height: 100%;
  }
}
