.rowContent {
    width: 100%;
}
.containerParent {
    height: 100vh;
    flex-direction:column;
}
.containerChild {
    margin-top: 20%;
}

@media screen and (max-width: 800px) {
    .containerChild {
        margin-top: 125px;
    }
}