.blue-login {
  background-color: #151a35;
  padding: 55px;
  min-height: 100vh;
}

.blue-login .home-container {
  height: unset !important;
}

.blue-login .info-column {
  padding-left: 0;
}

.blue-login header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: unset;
  margin-bottom: 0;
  color: white;
}

.blue-login h1,
.blue-login p {
  color: white;
}

.download-container {
  display: block;
  width: max-content;
  margin-left: auto;
  gap: 10px;
  color: white;
}

.download-container .apps-container {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  font-size: 12px;
}

.info-column,
.background-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
}

.info-column {
  width: 40%;
}

.background-column {
  width: 60%;
}

.background-column img {
  width: 100%;
  display: block;
  margin: auto;
}

.vid-chain-buttons-container {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 37px;
}

.vid-chain-buttons-container .new-sign-in-vid-chain {
  width: 95%;
}

.vid-chain-buttons-container button,
.vid-chain-buttons-container a {
  height: 51px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  border-radius: 2px;
}

.vid-chain-buttons-container .new-sign-in-vid-chain:nth-child(1) {
  background: white;
  margin-bottom: 19px;
  border: 2px solid white;
  color: #151a35;
  font-family: TT-Norms;
  padding: 7.5px 15.5px;
}

.vid-chain-buttons-container .new-sign-in-vid-chain:hover {
  border: 1px solid #9A6DF9;
  background-color: #9A6DF9;
  color: white;
}

.vid-chain-buttons-container .new-sign-in-vid-chain:nth-child(2) {
  background: #151a35;
  border: 1px solid #fff;
  margin-bottom: 19px;
  font-weight: bold;
  color: white;
  padding: 7.5px 15.5px;
  width: 95%;
  text-decoration: none;
}

.vid-chain-buttons-container .new-sign-in-vid-chain:nth-child(2):hover {
  background: white;
  border: 2px solid #151a35;
  margin-bottom: 19px;
  font-weight: bold;
  color: #151a35;
}

@media (max-width: 600px) {
  .home-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 150px;
  }

  .info-column,
  .background-column {
    width: 100%;
  }
}
