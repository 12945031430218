@font-face {
  font-family: 'TT-Norms';
  src: url(assets/fonts/TTNorms-Medium_variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Regular';
  src: url(assets/fonts/TTNorms-Regular-variable.otf);
}

@font-face {
  font-family: 'TT-Norms-Black';
  src: url(assets/fonts/TTNorms-ExtraBold-variable.otf);
}

body {
  margin: 0;
  font-family:  TT-Norms-Regular ,-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1{
  font-family: TT-Norms-Black;
  font-size: 40px;
}
button{
  font-family: TT-Norms-Black;
  font-size: 18px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}
