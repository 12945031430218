.headerHome {
  padding: 15px 15px 15px 15px;
  right: 10%;
  display: flex;
  width: 300px;
  width: auto;
  align-items: center;
  justify-content: center;
}
.iconHeaderHome {
  margin-right: 1%;
  width: 32px;
  height: 32px;
}

.logout-icon {
  width: 40px;
  cursor: pointer;
}

.language-select {
  margin-right: 15px;
}
